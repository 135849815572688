import React from "react"
import { Link } from "gatsby"
import NavigationBar from "../components/Navbar"
import DataScienceCourseImg from "../img/data-science-course.svg"
import DataScienceInterviewImg from "../img/data-science.png"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"
const Footer2 = loadable(() => import("../components/footer3"))
const NewsletterStrip = loadable(() => import("../components/newsletterStrip"))
const SocialMedia = loadable(() => import("../components/social-media"))
const CoursesAndBlogs = loadable(() => import("../components/CoursesAndBlogs"))

export default class DataScienceCoursePage extends React.Component {
  render() {
    return (
      <div>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>Data Science Course</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="description"
            property="og:description"
            content="Project-based data science course by Enjoy Algorithms to master data science and prepare for tech interview. We have organized data science concepts and projects in a guided order."
          />
          <meta name="author" content="Ravish Raj" />
          <meta
            name="image"
            property="og:image"
            content={DataScienceInterviewImg}
          />
          <meta
            name="keywords"
            content="data science interview preparation, enjoyalgorithms data science course, data science online course, data science course enjoy algorithms, data science, data science course, data science online course, data science self-paced course, best data science interview preparation course"
          />
          <meta
            name="title"
            property="og:title"
            content="Data Science Interview Course"
          />
        </Helmet>

        <div className=" sm:sticky top-0 z-50">
          <NavigationBar />
        </div>

        <div className="bg-light-white">
          <div className="container mx-auto px-5 md:px-7 lg:px-8 xl:px-10">
            <div className="md:grid md:grid-cols-12">
              <div className="pt-4 md:pt-6 xl:pt-8 max-w-auto mx-auto col-span-7 ">
                <h1 className="tracking-wider text-xl md:text-2xl xl:text-3xl text-gray-900 text-left sm:text-center md:text-left">
                  Data Science Interview Course
                </h1>
                <p className="tracking-wider mt-3 sm:text-sm xl:text-base 2xl:text-lg text-gray-600 md:w-5/6">
                  A self-paced data science course for students and
                  professionals who are looking to apply data science to
                  industrial datasets, extract insights, and build a promising
                  career in data science.
                </p>
                <h2 className="sm:hidden lg:block mt-6 tracking-wider text-gray-900 text-lg md:text-xl xl:text-2xl">
                  What will you learn?
                </h2>
                <div className="mt-6 sm:hidden grid gap-4 sm:grid-cols-2 lg:grid text-gray-600 tracking-wider sm:text-sm xl:text-base 2xl:text-lg">
                  <p>&#10003; Data Science Fundamentals</p>
                  <p>&#10003; Data Analysis Techniques</p>
                  <p>&#10003; Python and ML Fundamentals</p>
                  <p>&#10003; Projects with Python Code</p>
                </div>
              </div>

              <div className="mt-6 md:mt-4 md:col-span-5 flex justify-center">
                <img
                  className="object-cover pointer-events-none w-72 h-40 sm:w-112 sm:h-64 md:w-72 md:h-40 lg:w-96 lg:h-52 xl:w-112 xl:h-64 2xl:w-128 2xl:h-72"
                  src={DataScienceCourseImg}
                  alt="enjoyalgorithms data science course"
                />
              </div>
            </div>

            <h2 className="tracking-wider text-gray-900 mt-6 hidden sm:block lg:hidden text-lg 2xl:text-2xl">
              What will you learn?
            </h2>

            <div className="mt-4 hidden tracking-wider sm:grid lg:hidden sm:text-sm xl:text-base 2xl:text-lg text-gray-600 gap-3 grid-cols-2">
              <p>&#10003; Data Science Fundamentals</p>
              <p>&#10003; Data Analysis Techniques</p>
              <p>&#10003; Python and ML Fundamentals</p>
              <p>&#10003; Projects with Python Code</p>
            </div>

            <div className="sm:flex sm:justify-between">
              <a
                href="https://www.linkedin.com/in/ravishkumar2/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="z-30 mt-6 text-center tracking-wider text-sm md:text-base 2xl:text-lg justify-end py-1 text-gray-600 hover:text-new-blue underline">
                  <p> Mentor and Author: Ravish Kumar</p>
                </div>
              </a>

              <div className="flex justify-end">
                <SocialMedia />
              </div>
            </div>

            <div className="mt-4 md:mt-6 xl:mt-8 tracking-wider tracking-wider grid gap-6 text-base sm:text-sm xl:text-base grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              <div className="overflow-x-auto bg-light-white p-1">
                <h3 className="text-base sm:text-sm md:text-base xl:text-lg 2xl:text-xl pr-6 text-gray-900  tracking-wider">
                  <span> Python Basics (Module 1)</span>
                </h3>

                <table className="mt-3 w-full">
                  <tbody>
                    <tr className="h-12">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">1.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/basic-python-for-ml-data-science/">
                              Introduction to Data Types
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">2.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/python-tuples-lists-for-ml-data-science/">
                              Tuples and Lists
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">3.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/sets-and-dictionaries-in-python-for-ml/">
                              Sets and Dictionaries
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">4.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/conditions-and-branching-in-python/">
                              Conditions and Branching
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">5.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/loops-in-python-for-ml-data-science/">
                              Introduction to Loop
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">6.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/introduction-to-function-in-python/">
                              Introduction to Functions
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">7.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/introduction-to-oops-in-python/">
                              Introduction to OOPS
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">8.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-numpy-in-python/">
                              Introduction to Numpy
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">9.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-pandas/">
                              Introduction to Pandas
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="overflow-x-auto bg-light-white p-1">
                <h3 className="text-base sm:text-sm md:text-base xl:text-lg 2xl:text-xl pr-6 text-gray-900  tracking-wider">
                  <span> Kickstart to Data Science (Module 2)</span>
                </h3>
                <table className="mt-3 w-full ">
                  <tbody>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">1.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-data-science/">
                              What is Data Science?
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">2.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/guide-to-become-data-scientist/">
                              Guide to Become a Data Scientist
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">3.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-jupyter-notebook/">
                              Installation of Jupyter-notebook
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">4.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-big-data/">
                              Introduction to Big Data
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">5.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-hadoop-framework/">
                              Introduction to Hadoop
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">6.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/api-introduction-for-data-science-and-ml/">
                              Introduction to APIs
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">7.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-sql-for-data-science/">
                              Introduction to SQL
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">8.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-database-for-data-science/">
                              Introduction to Databases
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="overflow-x-auto bg-light-white p-1 ">
                <h3 className="text-base sm:text-sm md:text-base xl:text-lg 2xl:text-xl pr-6 text-gray-900  tracking-wider">
                  <span>
                    Data Analysis, Pre-processing and Visualization (Module 3)
                  </span>
                </h3>

                <table className="mt-3 w-full ">
                  <tbody>
                    <tr className="h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">1.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-data-visualization-in-data-science/">
                              Introduction to Data Visulaization
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">2.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-matplotlib/">
                              Introduction to Matplotlib
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">3.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-seaborn-library-in-python/">
                              Introduction to Seaborn
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">4.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/data-pre-processing-for-machine-learning/">
                              Introduction to Data Pre-processing
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">5.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/structured-data-pre-processing-hands-on/">
                              Data pre-processing hands-on
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">6.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/univariate-bivariate-multivariate-analysis/">
                              Exploratory data analysis
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">7.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/feature-engineering-for-machine-learning/">
                              Feature Engineering
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">8.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/feature-selection-techniques/">
                              Feature Selection Techniques
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">9.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-anomaly-detection/">
                              Anomaly Detection
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="overflow-x-auto bg-light-white p-1">
                <h3 className="text-base sm:text-sm md:text-base xl:text-lg 2xl:text-xl pr-6 text-gray-900  tracking-wider">
                  Understanding Various forms of Data (Module 4)
                </h3>

                <table className="mt-3 w-full ">
                  <tbody>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">1.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/need-of-feature-scaling-in-machine-learning/">
                              Need of Feature scaling
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">2.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/regular-expressions-in-ml/">
                              Introduction to Regex
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">3.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/regex-applications-in-data-science/">
                              Applications of Regex
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">4.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/text-data-pre-processing-techniques-in-ml/">
                              Text Data Pre-processing
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">5.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/word-vector-encoding-in-nlp/">
                              Vector Encoding
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">6.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/pre-processing-time-series-data/">
                              Pre-processing Time Series Data
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-12">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">7.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/handling-date-time-python/">
                              Handling Data-time with Python
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="overflow-x-auto bg-light-white p-1 ">
                <h3 className="text-base sm:text-sm md:text-base xl:text-lg 2xl:text-xl pr-6 text-gray-900  tracking-wider">
                  <span>Machine Learning Concepts (Module 5)</span>
                </h3>
                <table className="mt-3 w-full ">
                  <tbody>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">1.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/introduction-to-machine-learning/">
                              Introduction to Machine Learning
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">2.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/cost-function-in-machine-learning/">
                              How exactly machine learns?
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">3.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/parameter-learning-and-gradient-descent-in-ml/">
                              Gradient Descent Algorithm
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">4.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blogs/supervised-unsupervised-and-semisupervised-learning/">
                              Supervised vs Unsupervised
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">5.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blogs/classification-and-regression-in-machine-learning/">
                              Classification vs Regression
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">6.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/regularization-in-machine-learning/">
                              Introduction to Regularization
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">7.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/evaluation-metrics-classification-models/">
                              Evaluation for Classification
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">8.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/evaluation-metrics-regression-models/">
                              Evaluation for Regression
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">9.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/introduction-to-scikit-learn/">
                              Introduction to Scikit-Learn
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="overflow-x-auto bg-light-white p-1 ">
                <h3 className="text-base sm:text-sm md:text-base xl:text-lg 2xl:text-xl pr-6 text-gray-900  tracking-wider">
                  <span> Popular Machine Learning Algorithms (Module 6)</span>
                </h3>
                <table className="mt-3 w-full ">
                  <tbody>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">1.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/linear-regression-in-machine-learning/">
                              Linear Regression
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="h-12">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">2.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/logistic-regression-in-ml/">
                              Logistic Regression
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="h-12">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">3.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/support-vector-machine-in-ml/">
                              Support Vector Machines
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="h-12">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">4.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1">
                            <Link to="/blog/k-means-clustering-algorithm/">
                              K-means Clustering Algorithm
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">5.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/principal-component-analysis-in-ml/">
                              Principal Component Analysis
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">6.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/tsne-algorithm-in-ml/">
                              t-SNE Algorithm
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">7.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/customer-segmentation-using-hierarchical-clustering/">
                              Hierarchical Clustering
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">8.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/apriori-algorithm-in-data-mining/">
                              Apriori Algorithm
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="overflow-x-auto bg-light-white p-1 ">
                <h3 className="text-base sm:text-sm md:text-base xl:text-lg 2xl:text-xl pr-6 text-gray-900  tracking-wider">
                  <span>Data Science Projects (Module 7)</span>
                </h3>
                <table className="mt-3 w-full">
                  <tbody>
                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">1.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/life-expectancy-prediction-using-linear-regression/">
                              Life Expectancy Prediction
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">2.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/attrition-rate-prediction-using-ml/">
                              Employee Attrition Rate Prediction
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">3.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/boston-house-price-prediction-using-machine-learning/">
                              Boston House Price prediction
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">4.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/image-compression-using-pca/">
                              Image Compression using PCA
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="h-12">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">5.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/personality-prediction-using-ml/">
                              Personality Prediction
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">6.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/music-recommendation-system-using-ml/">
                              Music Recommendation System
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">7.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/time-series-forecasting-using-machine-learning/">
                              Time Series Forecasting
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className=" h-12 ">
                      <td className="">
                        <div className="flex items-center 2xl:text-lg">
                          <p className="pr-2 text-gray-900">8.</p>

                          <div className="text-new-blue py-1 hover:underline pl-1 ">
                            <Link to="/blog/customer-churn-prediction-using-ml/">
                              Customer Churn Prediction
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-6">
              <NewsletterStrip />
            </div>
            <div className="mt-6">
              <CoursesAndBlogs />
            </div>
            <Footer2 />
          </div>
        </div>
      </div>
    )
  }
}
